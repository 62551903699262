import axiosClients from "./axiosClients";

const UserAPI  = {
    getAll: (param) => {
        const url = '/client/api/v1/user/list';
        return axiosClients.post(url,{param});
    },

    getCount: (param) => {
        const url = '/client/api/v1/user/count';
        return axiosClients.post(url,{param});
    },
}

export  default  UserAPI;