import axios from 'axios';
import queryString from 'query-string';
//process.env.RIO_APP_API_URL,
const axiosClients = axios.create({
    baseURL: 'http://localhost:5000',
    headers: {
        'content-type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsInVzZXJuYW1lIjoiaHVodWh1IiwiaWF0IjoxNjUwNjIxNTA3LCJleHAiOjE2NTEyMjE1MDd9.jzl0UwBc3Y6KyQMDQib-xd3FROKN-ICSzvWHpwRPZ1k',
    },
    paramsSerializer: params => queryString.stringify(params)
})

axiosClients.interceptors.request.use(async (config)=>{
    return config;
})

axiosClients.interceptors.response.use((response)=>{
    if (response&& response.data){
        return response.data;
    }
    return response
    }, (error)=>{
        throw error;
    })

export default axiosClients;