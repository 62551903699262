import "./login.scss"
//import {useState} from "react";

const Login = () => {
    return (
        <div id="login">
            {/*<form action="" id="form-login">*/}
            {/*    <h1 className="form-heading">Login</h1>*/}
            {/*    <div className="form-group">*/}
            {/*        <input type="input" placeholder="username" className="form-input"*/}
            {/*               onChange={e => setDetail({...detail, name:e.target.value})} value={detail.name}*/}
            {/*               ></input>*/}

            {/*    </div>*/}
            {/*    <div className="form-group">*/}
            {/*        <input type="input" placeholder="password" className="form-input"></input>*/}
            {/*    </div>*/}
            {/*    <input type="submit" placeholder="Login" className="form-submit"></input>*/}
            {/*</form>*/}
        </div>
    )
}

export default Login