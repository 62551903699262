import "./itemvariant.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState , useEffect} from "react";
import ItemvariantAPI from "../../api/itemvariantAPI";

const itemvariantColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
        field: "variantcode",
        headerName: "Variant Code",
        width: 150,
    },
    {
        field: "itemno",
        headerName: "Item No",
        width: 200,
    },
    {
        field: "itemname",
        headerName: "Item Name",
        width: 150,
    },
    {
        field: "lotno",
        headerName: "Lot No",
        width: 200,
    },
    {
        field: "unitofmeasure",
        headerName: "Unit of Measure",
        width: 200,
    },
    {
        field: "quantity",
        headerName: "Quantity",
        width: 200,
    },
    {
        field: "isActive",
        headerName: "Active",
        width: 100,
        renderCell: (params) => {
            const active = params.row.isActive;
            var cellstatus = '';
            if (active == 1){
                cellstatus = 'active'
            }else{
                cellstatus = 'passive'
            }
            return (
                <div className={`cellWithStatus ${cellstatus}`}>
                    {cellstatus}
                </div>
            );
        },
    },
];


const Itemvariant = () => {

    const [itemvariantData, setItemvariantdata] = useState([]);

    useEffect(()=>{
        const fetchItemvariantList = async  () => {
            const response = await ItemvariantAPI.getAll();
            setItemvariantdata(response.data.data);
            console.log(response);
            try {
            }catch (e){
                console.log(e);
                setItemvariantdata([]);
            }
        };
        fetchItemvariantList();
    },[])


    // const handleDelete = (id) => {
    //   setData(data.filter((item) => item.id !== id));
    // };

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <Link to="/users/test" style={{ textDecoration: "none" }}>
                            <div className="viewButton">View</div>
                        </Link>
                        <div
                            className="deleteButton"
                            //onClick={() => handleDelete(params.row.id)}
                        >
                            Delete
                        </div>
                    </div>
                );
            },
        },
    ];
    return (
        <div className="datatable">
            <div className="datatableTitle">
                Import Variant Code
                <Link to="/users/new" className="link">
                    Import New
                </Link>
            </div>
            <DataGrid
                className="datagrid"
                rows={itemvariantData}
                columns={itemvariantColumns.concat(actionColumn)}
                pageSize={9}
                rowsPerPageOptions={[9]}
                checkboxSelection
            />
        </div>
    );
};

export default Itemvariant;
