import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState , useEffect} from "react";
import UserAPI from "../../api/userAPI";

const userColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
        field: "username",
        headerName: "User Name",
        width: 150,
        // renderCell: (params) => {
        //     return (
        //         <div className="cellWithImg">
        //             <img className="cellImg" src={params.row.img} alt="avatar" />
        //             {params.row.username}
        //         </div>
        //     );
        // },
    },
    {
        field: "name",
        headerName: "Name",
        width: 200,
    },
    {
        field: "mobileNo",
        headerName: "Phone No",
        width: 150,
    },
    {
        field: "email",
        headerName: "Email",
        width: 200,
    },
    {
        field: "isActive",
        headerName: "Active",
        width: 100,
        renderCell: (params) => {
            const active = params.row.isActive;
            var cellstatus = '';
            if (active == 1){
                cellstatus = 'active'
            }else{
                cellstatus = 'passive'
            }
            return (
                <div className={`cellWithStatus ${cellstatus}`}>
                    {cellstatus}
                </div>
            );
        },
    },
];


const Datatable = () => {
  //const [data, setData] = useState(userRows);
  const [userdata, setUserdata] = useState([]);

  useEffect(()=>{
      const fetchUserList = async  () => {
          const response = await UserAPI.getAll();
          setUserdata(response.data.data);
          console.log(response);
        try {
        }catch (e){
            console.log(e);
            setUserdata([]);
        }
      };
      fetchUserList();
  },[])


  // const handleDelete = (id) => {
  //   setData(data.filter((item) => item.id !== id));
  // };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to="/users/test" style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              //onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add New User
        <Link to="/users/new" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={userdata}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default Datatable;
