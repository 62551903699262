import axiosClients from "./axiosClients";

const ItemvariantAPI = {
    getAll: (param) => {
        const url = '/client/api/v1/itemvariant/list';
        return axiosClients.post(url,{param});
    },

}

export default ItemvariantAPI;

